<template>
  <div>
    <b-modal
      id="edit-illness-sidebar"
      centered
      :visible="isEditIllnessSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="sm"
      backdrop
      no-header
      right
      :title="$t('updateIllness')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankIllnessData)"
      @hidden="formValidation(resetblankillness).resetForm"
      @change="(val) => changed(val)"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankillness
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- illnessName -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="illnessName"
            class="w-100"
          >
            <b-form-group :label="$t('illnessName')" label-for="illnessName">
              <b-form-input
                id="eaddillnessName"
                v-model="blankIllnessData.illnessname"
                autofocus
                :state="
                  formValidation(resetblankillness).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="animalIllnessType"
            rules="required"
            class="w-100"
          >
            <b-form-group
              :label="$t('illnessesType')"
              label-for="animalIllnessType"
            >
              <v-select
                id="addillnesstypes"
                :key="illnesstypeOptions.map((x) => x.label).join(',')"
                v-model="blankIllnessData.illnesstypeid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="illnesstypeOptions"
                class="w-100"
                :reduce="(val) => val.value"
                @input="
                  (val) => {
                    selectedIllnessType = val;
                  }
                "
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditIllnessSidebarActive",
    event: "update:is-edit-illness-sidebar-active",
  },
  props: {
    isEditIllnessSidebarActive: {
      type: Boolean,
      required: true,
    },
    illness: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankIllnessData: { ...this.illness },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      selectedIllness: {},
      illnesstypeOptions: [],
      selectedIllnessType: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  async created() {
    this.fetchTypes();
  },
  methods: {
    async fetchTypes() {
      var data = await this.$store.dispatch(
        "definitionsModule/fetchIllnessType"
      );
      data.map((x) =>
        this.illnesstypeOptions.push({
          label: x.illnesstypename,
          value: x.id,
          cid: x.companyid,
        })
      );
    },
    async changed(val) {
      this.imgStr = "";
      this.resetblankillness();
      this.$emit("update:is-edit-illness-sidebar-active", val);
    },
    onSubmit() {
      this.blankIllnessData.imgstr = this.imgStr;
      store
        .dispatch("definitionsModule/updateIllness", this.blankIllnessData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-illness-sidebar-active", false);
        });
    },
    async resetblankillness() {
      this.blankIllnessData = { ...this.illness, illnessid: this.illness.id };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-illness-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
