<template>
  <div><illness-list /></div>
</template>

<script>
import IllnessList from "./components/IllnessList/IllnessList.vue";
export default {
  components: { IllnessList },
};
</script>

<style></style>
