<template>
  <div>
    <b-modal
      id="addadd-new-illness-sidebar"
      centered
      :visible="isAddNewIllnessSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="sm"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('illnessAdd')"
      @ok="onSubmit(blankIllnessData)"
      @hidden="formValidation(resetblankillness).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        :ref="formValidation(resetblankillness).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- illnessName -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="illnessName"
            class="w-100"
          >
            <b-form-group :label="$t('illnessName')" label-for="illnessName">
              <b-form-input
                id="addillnessName"
                v-model="blankIllnessData.illnessName"
                autofocus
                :state="
                  formValidation(resetblankillness).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="illnesstypes"
            rules=""
            class="w-100"
          >
            <b-form-group :label="$t('illnessesType')" label-for="illnesstypes">
              <v-select
                id="addillnesstypes"
                :key="illnesstypeOptions.map((x) => x.label).join(',')"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="illnesstypeOptions"
                class="w-100"
                @input="
                  (val) => {
                    blankIllnessData.illnesstypeid = val.value;
                    selectedIllnessType = val;
                  }
                "
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewIllnessSidebarActive",
    event: "update:is-add-new-illness-sidebar-active",
  },
  props: {
    isAddNewIllnessSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankIllnessData: {
        id: 0,
        companyid: getUserData().companyid,
        illnessName: "",
        illnesstypeid: 0,
      },
      formValidation: formValidation,
      illnesstypeOptions: [],
      selectedIllnessType: null,
    };
  },
  async created() {
    this.fetchTypes();
  },
  async mounted() {},
  methods: {
    editType(illnessType) {
      this.selectedIllnessType = illnessType;
      this.$refs.typeEditModal.openModal();
    },
    async fetchTypes() {
      var data = await this.$store.dispatch(
        "definitionsModule/fetchIllnessType"
      );
      this.illnesstypeOptions = [];
      this.illnesstypeOptions = [
        ...data.map((x) => {
          return {
            label: x.illnesstypename,
            value: x.id,
            ...x,
          };
        }),
      ];
      this.$forceUpdate();
    },
    async changed(val) {
      this.$emit("update:is-add-new-illness-sidebar-active", val);
      this.resetblankillness();
    },
    removeType() {
      store
        .dispatch(
          "definitionsModule/deleteIllnessType",
          this.selectedIllnessType.id
        )
        .then((val) => {
          if (val != false) {
            this.fetchTypes();
          }
        });
    },
    async onSubmit() {
      await store
        .dispatch("definitionsModule/addIllness", this.blankIllnessData)
        .then((val) => {
          if (val != false) {
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-illness-sidebar-active", false);
          }
        });
    },
    async resetblankillness() {
      this.blankIllnessData = {
        id: 0,
        companyid: getUserData().companyid,
        illnessName: "",
        illnesstypeid: 0,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-illness-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
