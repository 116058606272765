<template>
  <div>
    <illness-list-add-new
      :is-add-new-illness-sidebar-active.sync="isAddNewIllnessSidebarActive"
      @refetch-data="fetchIllnesses"
    />
    <illness-list-edit
      :key="JSON.stringify(selectedIllness)"
      :is-edit-illness-sidebar-active.sync="isEditIllnessSidebarActive"
      :illness.sync="selectedIllness"
      @refetch-data="fetchIllnesses"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="
                  isAddNewIllnessSidebarActive = !isAddNewIllnessSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("newIllness") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refIllnessListTable"
        class="position-relative"
        :items="filteredIllnesses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(pictureurl)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  'https://api.herdoncloud.com/Illness_Pictures/' +
                  data.item.pictureurl
                "
                :text="avatarText(data.item.illnessname)"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap" />
            <small class="text-muted">@{{ data.item.responsible }}</small>
          </b-media>
        </template>

        <template #cell(delete)="data">
          <b-link
            v-if="data.item.allowDelete"
            @click="deleteIllness(data.item.id)"
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link
            v-if="data.item.allowEdit == 1"
            @click="openEditSideBar(data)"
          >
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredIllnesses.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BMedia,
  BAvatar,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useIllnessList from "../../useIllnessList";
import IllnessListAddNew from "./IllnessListAddNew.vue";
import IllnessListEdit from "./IllnessListEdit.vue";

import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    IllnessListAddNew,
    IllnessListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BMedia,
    BAvatar,
    vSelect,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },

  setup({ emit }) {
    const isAddNewIllnessSidebarActive = ref(false);
    const isEditIllnessSidebarActive = ref(false);
    const selectedIllness = ref({});
    const userData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedIllness.value = item;
      isEditIllnessSidebarActive.value = !isEditIllnessSidebarActive.value;
    };
    const {
      fetchIllnesses,
      tableColumns,
      perPage,
      currentPage,
      totalIllnesses,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIllnessListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,
      allIllnesses,
      filteredIllnesses,
      deleteIllness,
    } = useIllnessList();
    fetchIllnesses();
    return {
      // Sidebar
      isAddNewIllnessSidebarActive,
      isEditIllnessSidebarActive,
      openEditSideBar,

      selectedIllness,

      fetchIllnesses,
      tableColumns,
      perPage,
      currentPage,
      totalIllnesses,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIllnessListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      filteredIllnesses,

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteIllness,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
